<div class="fs-user-navbar">
  <div class="fs-user-toggle" [matMenuTriggerFor]="beforeMenu">
    <fa-icon [icon]="faUserCircle"></fa-icon>
    <label *ngIf="!noName && (store$ | async)?.user">{{ (store$ | async)?.user?.fullname }}</label>
  </div>
  <mat-menu #beforeMenu="matMenu" xPosition="before">
    <div *ngIf="(store$ | async)?.user" mat-menu-item>
      <fa-icon [icon]="faUser" [ngStyle]="{ 'margin-right': '1rem' }"></fa-icon>
      <strong>{{ (store$ | async)?.user?.fullname }}</strong>
    </div>
    <mat-divider></mat-divider>
    <a [routerLink]="['/changelogs']" mat-menu-item>
      <fa-icon [icon]="faQuestionCircle" style="margin-right: 1rem"></fa-icon>
      <span>Co je nového</span>
    </a>
    <mat-divider></mat-divider>
    <button mat-menu-item (click)="logOut()">
      <fa-icon [icon]="faPowerOff" style="margin-right: 1rem"></fa-icon>
      <span>Odhlásit se</span>
    </button>
    <div mat-menu-item>
      <fs-version-display></fs-version-display>
    </div>
  </mat-menu>
</div>
