import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { UserNavbarComponent } from './user-navbar.component'
import { MatDividerModule } from '@angular/material/divider'
import { MatMenuModule } from '@angular/material/menu'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { MatRippleModule } from '@angular/material/core'
import { RouterModule } from '@angular/router'
import { VersionDisplayModule } from '../../shared/components/version-display/version-display.module'

@NgModule({
  declarations: [UserNavbarComponent],
  imports: [CommonModule, RouterModule, MatDividerModule, MatMenuModule, FontAwesomeModule, MatRippleModule, VersionDisplayModule],
  exports: [UserNavbarComponent],
})
export class UserNavBarModule {}
