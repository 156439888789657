import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Changelog } from 'apps/client/src/app/changelogs/models/Changelog';
import { DataService } from 'apps/client/src/core/services/base/data.service';

@Injectable({
  providedIn: 'root',
})
export class VisionDisplayService extends DataService<Changelog> {
  private _version?: string | undefined;

  constructor(private httpClient: HttpClient) {
    super(httpClient, '/api/changelogs');
  }

  public loadVersion() {
    const subscription = this.getData(1, 1).subscribe((res) => {
      this.version = (res.items || [])[0]?.version;
      subscription.unsubscribe();
    });
  }

  public get version(): string | undefined {
    return this._version;
  }
  public set version(value: string | undefined) {
    this._version = value;
  }
}
