import { Component, OnInit } from '@angular/core'
import { VisionDisplayService } from './vision-display.service'

@Component({
  selector: 'fs-version-display',
  template: `
    <div *ngIf="service.version">
      <div class="fs-version-toggle">
        <label>{{ service.version }}</label>
      </div>
    </div>
  `,
  styleUrls: ['./version-display.component.scss'],
})
export class VersionDisplayComponent implements OnInit {
  constructor(public service: VisionDisplayService) {}

  ngOnInit(): void {
    if (!this.service.version) this.service.loadVersion()
  }
}
