import { Component, Input } from '@angular/core'
import { faPowerOff, faUserCircle, faUser } from '@fortawesome/free-solid-svg-icons'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs/internal/Observable'
import { AppState, IAuthState } from '../../../core/reducers'
import { AuthService } from '../../login/services/auth.service'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'

@Component({
  selector: 'fs-user-navbar',
  templateUrl: './user-navbar.component.html',
  styleUrls: ['./user-navbar.component.scss'],
})
export class UserNavbarComponent {
  @Input() noName = false
  store$: Observable<IAuthState>
  // icons
  faUserCircle = faUserCircle
  faPowerOff = faPowerOff
  faUser = faUser
  faQuestionCircle = faQuestionCircle

  constructor(public authService: AuthService, private store: Store<AppState>) {
    this.store$ = this.store.select('auth')
  }

  logOut() {
    this.authService.logOut()
  }
}
